import React from 'react';
import styles from './Contact.module.css';
import { FaEnvelope } from 'react-icons/fa';

const Contact = () => {
  return (
    <div className={styles.container}>
      <div className={styles.contactInfo}>
        <p>
          If you would like to contact us or leave feedback, don't hesitate! You can contact us by e-mail.
        </p>
        <p>
          Your e-mail inbox - 
          <a className={styles.emailLink} href="mailto:rest-au-top@gmail.com">
            <FaEnvelope className={styles.icon} /> rest-au-top
          </a>
        </p>
      </div>
      <div className={styles.researchInfo}>

        
      </div>
    </div>
  );
};

export default Contact;
