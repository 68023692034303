import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css';
import CasinoHotels from './components/homepage/casinohotel';
import About from './components/About/About';
import Hotels from './components/Hotels/Hotels';
import Hotoffers from './components/HotOffers/Hotoffers';
import Footer from './components/Footer/Footer';
import Navbar from './components/Navbar/Navbar';
import Privacy from './components/Privacy/Privacy';
import Contact from './components/Contact/Contact';
import Cookies from './components/Cookies/Cookies';

const App = () => {
  return (
    <div>
      <CasinoHotels />
      <About />
      <Hotels />
      <Hotoffers />
    </div>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Router>
    <Navbar />
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/about" element={<About />} />
        <Route path="/hotels" element={<Hotels />} />
        <Route path="/offers" element={<Hotoffers />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path=" Cookies" element={< Cookies />} />
      </Routes>
      <Footer />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);