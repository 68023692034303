import React from 'react';
import styles from './Cookies.module.css';

const Cookies = () => {
  return (
    <div className={styles.privacyPolicy}>

      <h1>Cookie policy for rest-au-top.com</h1>
      <p>
      This is the cookie policy for grajwzakryty.com, available at rest-au-top.com
      </p>
      <h2>What are cookies</h2>
      <p>
      As is usual with almost all professional websites, this site uses cookies, which are small files that are downloaded to your computer to improve your experience. This page describes what information they have we collect, how we use them and why we sometimes need to store these cookies. We will also share how we can prevent these cookies from being stored, however, this may weaken or 'spoil' some elements of the City's websites functionality.
      </p>
      
      <h2>How we use cookies</h2>
      <p>
      We use cookies for various reasons described below. Unfortunately, in most cases the industry does not default to options to disable cookies without completely disabling functionality and features such as they add to this website. It is recommended that you leave all cookies if you are not sure whether you need them or not if they are used to provide the service you are using.
      </p>
      <h2>Disable cookies</h2>
      <p>
      You can prevent cookies from being stored by changing the settings of your browser (see your browser's help Do how to do this). Please note that disabling cookies will affect the functionality of this and many other features of the websites you visit. Disabling cookies will usually also disable certain functions AND features on this website. It is therefore not recommended that you disable cookies.
      </p>
      <h2>Information cookies we set</h2>
      <p>Website settings cookies</p>
      <p>In order to provide you with a good experience when using this website, we offer a feature that allows you to enter your settings when you use this website. In order to remember your preferences, we need to place a cookie so that this information can be retrieved each time you interact with the site your settings.</p>
      <h2>Third-party cookies</h2>
      <p>
      In some specific cases, we also use cookies from trusted third parties. The following section details what third-party cookies you may encounter on this site.
      </p>
      <p>This site uses Google Analytics, one of the most widely used and trusted analytics solution tools in the online world, because you help us understand how you use the site and how we can improve your experience. These cookies can track, for example, the time you spend on the site and which pages you visit, so we can continue to create interesting content.</p>
      <p>More information on Google Analytics cookies can be found on the official Google Analytics website.</p>
      <h2>More information</h2>
      <p>
      We hope we've made things clearer for you, and as we mentioned earlier, if there's something like this you're not sure whether you need them or not, it's usually safer to leave cookies enabled just in case you interact with one of the features you use on our website.
      </p>
    </div>
  );
};

export default Cookies;
