import React from 'react';
import telegram from '../Assets/telegram.svg';
import instagram from '../Assets/instagram.svg';
import styles from './Footer.module.css';
import Age from './Age.png';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className={styles.footer} id='contacts'>
      <div className={styles.leftContent}>
        <div className={styles.contactInfo}>
          <p>If you would like to contact us or leave feedback, don't hesitate! You can contact us by e-mail.</p>
          <p>Your e-mail inbox - <a className={styles.emailLink} href="mailto:rest-au-top@gmail.com">rest-au-top.com</a></p>
        </div>
        <div className={styles.researchInfo}>
          <p>"rest-au-top" - do your research to get the best experience. By staying on the website, you accept the use of cookies.</p>

          <Link className={styles.emailLink} to=" Cookies">More info</Link>
        
        </div>
      </div>
      <div className={styles.rightContent}>
        <div className={styles.footerIcons}>
          <a href="https://web.telegram.org/"><img src={telegram} alt='telegram' title='telegram'/></a>
          <a href="https://www.instagram.com/"><img src={instagram} alt='instagram' title='instagram'/></a>
        </div>
        <div className={styles.disclaimer}>
          <p>All information on our website is for information purposes only.</p>
        </div>
        <div className={styles.footerIcons2}>
          <a href="https://web.telegram.org/"><img src={telegram} alt='telegram' title='telegram'/></a>
          <a href="https://www.instagram.com/"><img src={instagram} alt='instagram' title='instagram'/></a>
        </div>
        <div className={styles.age}>
          <img src={Age} alt="18+" />
        </div>
      </div>
      <div className={styles.privacy}>
        <Link to="/privacy">Privacy policy</Link>
      </div>
    </footer>
  );
};

export default Footer;
