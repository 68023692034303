import React from 'react';
import styles from './Hotoffers.module.css';
import StarSydney from '../Assets/The Star Sydney.png';
import CrownPerth from '../Assets/Crown Perth.png';
import SkyCityAdelaide from '../Assets/SkyCity Adelaide.png';


const HotOffers = () => {
  const offers = [
    {
      image: StarSydney,
      title: 'The Star Sydney',
      regularPrice: '400€',
      salePrice: '380€',
      link: 'https://www.booking.com/hotel/au/the-star-grand-residences.en-gb.html?aid=356980&label=gog235jc-1DCAsoD0IZdGhlLXN0YXItZ3JhbmQtcmVzaWRlbmNlc0gzWANotgGIAQGYAQm4ARfIARXYAQPoAQGIAgGoAgO4AoXbwLMGwAIB0gIkNDdkYjcxY2UtYzEwZi00NTE4LWE1OGQtMWVhOTBkNDllODdm2AIE4AIB&sid=86777a981f210741289092eaaa1594d4&dist=0&group_adults=2&keep_landing=1&sb_price_type=total&type=total&',
    },
    {
      image: CrownPerth,
      title: 'Crown Perth',
      regularPrice: '375€',
      salePrice: '355€',
      link: 'https://www.booking.com/hotel/au/crown-metropol-perth.en-gb.html?aid=356980&label=gog235jc-1DCAsoD0IUY3Jvd24tbWV0cm9wb2wtcGVydGhIM1gDaLYBiAEBmAEJuAEXyAEV2AED6AEBiAIBqAIDuAKK3cCzBsACAdICJGRiNDIyYzkyLTdkYzctNDk5Mi1iNzBhLTU1NzAxODVjOTQ2MtgCBOACAQ&sid=86777a981f210741289092eaaa1594d4&dist=0&group_adults=2&keep_landing=1&sb_price_type=total&type=total&',
    },
    {
      image: SkyCityAdelaide,
      title: 'SkyCity Adelaide',
      regularPrice: '320€',
      salePrice: '300€',
      link: 'https://www.booking.com/hotel/au/eos-by-skycity.en-gb.html',
    },
  ];

  return (
    <div className={styles.container}>
      <h2 className={styles.sectionHeader}>Hot offers</h2>
      <div className={styles.offerContainer}>
        {offers.map((offer, index) => (
          <div key={index} className={styles.offer}>
            <img src={process.env.PUBLIC_URL + offer.image} alt={offer.title} className={styles.offerImage} />
            <h3 className={styles.offerTitle}>{offer.title}</h3>
            <div className={styles.offerPrices}>
            <p className={styles.salePrice}>{offer.salePrice}</p>
            <p className={styles.regularPrice}>{offer.regularPrice}</p>
            </div>
            <a
            href={offer.link}
            target='blank'
            className={styles.moreInfoButton}
          >
            Book
          </a>
          </div>
        ))}
      </div>

    </div>
    
  );
};

export default HotOffers;