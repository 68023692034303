import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Navbar.module.css';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className={styles.header}>
      <nav className={`${styles.nav} ${menuOpen ? styles.open : ''}`}>
        <Link to="/" onClick={toggleMenu}>Home page</Link>
        <Link to="/about" onClick={toggleMenu}>About us</Link>
        <Link to="/hotels" onClick={toggleMenu}>Hotels</Link>
        <Link to="/offers" onClick={toggleMenu}>Hot offers</Link>
        <a href="#contacts" onClick={toggleMenu}>Contacts</a>
      </nav>
      <div className={`${styles.burger} ${menuOpen ? styles.open : ''}`} onClick={toggleMenu}>
        <div className={styles.line}></div>
        <div className={styles.line}></div>
        <div className={styles.line}></div>
      </div>
    </header>
  );
};

export default Navbar;