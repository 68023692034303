import React, { useState, useEffect } from 'react';
import styles from './casinohotel.module.css';

const CasinoHotels = () => {

  return (
    <div className={styles.container}>
      <main>
        <section className={styles.hero}>
          <h1>A review of the best casino hotels in Australia</h1>
          <p>Welcome to our website, where we offer you an overview of the best casino hotels in Australia. Enjoy a combination of gambling, luxury accommodation, and unique entertainment that makes your stay unforgettable.</p>
        </section>
      </main>
    </div>
  );
};

export default CasinoHotels;