import React, { useState } from 'react';
import styles from './Hotels.module.css';

import StarSydney from '../Assets/The Star Sydney.png';
import StarSydney2 from '../Assets/The Star Sydney 2.png';
import StarSydney3 from '../Assets/The Star Sydney 3.png';
import CrownMelbourne from '../Assets/Crown Melbourne.png';
import CrownMelbourne2 from '../Assets/Crown Melbourne 2.png';
import CrownMelbourne3 from '../Assets/Crown Melbourne 3.png';
import CrownPerth from '../Assets/Crown Perth.png';
import CrownPerth2 from '../Assets/Crown Perth 2.png';
import CrownPerth3 from '../Assets/Crown Perth 3.png';
import SkyCityAdelaide from '../Assets/SkyCity Adelaide.png';
import SkyCityAdelaide2 from '../Assets/SkyCity Adelaide 2.png';
import SkyCityAdelaide3 from '../Assets/SkyCity Adelaide 3.png';

const hotels = [
  {
    name: 'The Star Sydney',
    location: 'Sydney, New South Wales',
    description:
      'The Star Sydney is a luxury resort offering modern rooms and suites with bay views. The casino here is one of the largest in Australia, offering a wide range of slot machines and table games.',
    amenities: [
      'Casino with a variety of games',
      'World-class restaurants',
      'Spa and Fitness Center',
      'Theater and entertainment shows',
    ],
    images: [StarSydney, StarSydney2, StarSydney3],
    moreInfoUrl:
      'https://www.booking.com/hotel/au/the-star-grand-residences.en-gb.html?aid=356980&label=gog235jc-1DCAsoD0IZdGhlLXN0YXItZ3JhbmQtcmVzaWRlbmNlc0gzWANotgGIAQGYAQm4ARfIARXYAQPoAQGIAgGoAgO4AoXbwLMGwAIB0gIkNDdkYjcxY2UtYzEwZi00NTE4LWE1OGQtMWVhOTBkNDllODdm2AIE4AIB&sid=86777a981f210741289092eaaa1594d4&dist=0&group_adults=2&keep_landing=1&sb_price_type=total&type=total&',
  },
  {
    name: 'Crown Melbourne',
    location: '8 Whiteman Street, Southbank, Melbourne',
    description:
      "Crown Melbourne is Australia's largest entertainment complex with casinos, hotels, restaurants and stores on the banks of the Yarra River.",
    amenities: [
      'Over 200 table games and 2500 slot machines',
      'Crown Towers, Crown Metropol, Crown Promenade',
      'More than 40 venues, including Michelin-starred restaurants',
    ],
    images: [CrownMelbourne, CrownMelbourne2, CrownMelbourne3],
    moreInfoUrl:
      'https://www.booking.com/hotel/au/crown-towers.en-gb.html?aid=356980&label=gog235jc-1DCAsoD0IMY3Jvd24tdG93ZXJzSDNYA2i2AYgBAZgBCbgBF8gBFdgBA-gBAYgCAagCA7gC4dvAswbAAgHSAiRhOTNmYjBiNS1kNzMxLTRiMTItOTg1My0wNmFmYTU0ZDBiYmbYAgTgAgE&sid=86777a981f210741289092eaaa1594d4&dist=0&group_adults=2&keep_landing=1&sb_price_type=total&type=total&',
  },
  {
    name: 'Crown Perth',
    location: 'Great Eastern Highway, Burswood, Perth',
    description:
      'Crown Perth is a luxury entertainment complex located on the banks of the Swan River in Perth, offering a variety of leisure facilities including casinos, hotels and restaurants.',
    amenities: [
      'A wide range of table games including poker, roulette, blackjack and many slot machines',
      'Crown Metropol and Crown Promenade offer comfortable rooms and modern amenities',
    ],
    images: [CrownPerth, CrownPerth2, CrownPerth3],
    moreInfoUrl:
      'https://www.booking.com/hotel/au/crown-metropol-perth.en-gb.html?aid=356980&label=gog235jc-1DCAsoD0IUY3Jvd24tbWV0cm9wb2wtcGVydGhIM1gDaLYBiAEBmAEJuAEXyAEV2AED6AEBiAIBqAIDuAKK3cCzBsACAdICJGRiNDIyYzkyLTdkYzctNDk5Mi1iNzBhLTU1NzAxODVjOTQ2MtgCBOACAQ&sid=86777a981f210741289092eaaa1594d4&dist=0&group_adults=2&keep_landing=1&sb_price_type=total&type=total&',
  },
  {
    name: 'SkyCity Adelaide',
    location: 'North Terrace, Adelaide',
    description:
      'SkyCity Adelaide is located in the historic train station building in the heart of Adelaide and offers a combination of luxurious leisure facilities and entertainment.',
    amenities: [
      'Table games such as poker, blackjack, roulette, and many slot machines',
      'The SkyCity hotel with luxurious rooms, modern amenities and city views',
    ],
    images: [SkyCityAdelaide, SkyCityAdelaide2, SkyCityAdelaide3],
    moreInfoUrl: 'https://www.booking.com/hotel/au/eos-by-skycity.en-gb.html',
  },
];

const Hotels = () => {
  const [currentImageIndices, setCurrentImageIndices] = useState(
    hotels.map(() => 0)
  );

  const handleClickImage = (hotelIndex) => {
    console.log('click Image');
    setCurrentImageIndices((prevIndices) => {
      const newIndices = [...prevIndices];
      newIndices[hotelIndex] =
        (newIndices[hotelIndex] + 1) % hotels[hotelIndex].images.length;
      return newIndices;
    });
  };

  const handleIndicatorClick = (hotelIndex, imageIndex) => {
    console.log('click Indicator')
    setCurrentImageIndices((prevIndices) => {
      const newIndices = [...prevIndices];
      newIndices[hotelIndex] = imageIndex;
      return newIndices;
    });
  };

  const checkClick = () => {
    console.log('check')
  }

  return (
    <div className={styles.hotelsContainer}>
      <h2 className={styles.sectionHeader}>Hotels</h2>
      <div className={styles.redLine}></div>

      {hotels.map((hotel, hotelIndex) => (
        <div className={styles.hotelCard} key={hotelIndex}>
          <div className={styles.hotelDetails}>
            <h3>{hotel.name}</h3>
            <div className={styles.imageContainer2} onClick={() => handleClickImage(hotelIndex)}>
              <img
                src={hotel.images[currentImageIndices[hotelIndex]]}
                alt={hotel.name}
                className={styles.hotelImage}
              />
              <div className={styles.indicatorContainer}>
                {hotel.images.map((image, imageIndex) => (
                  <span
                    key={imageIndex}
                    className={`${styles.indicator} ${
                      currentImageIndices[hotelIndex] === imageIndex
                        ? styles.active
                        : ''
                    }`}
                    onClick={() => handleIndicatorClick(hotelIndex, imageIndex)}
                  />
                ))}
              </div>
            </div>
            <p>
              <strong>Location:</strong> {hotel.location}
            </p>
            <p>
              <strong>Description:</strong> {hotel.description}
            </p>
            <p>
              <strong>Amenities and Entertainment:</strong>
            </p>
            <ul>
              {hotel.amenities.map((amenity, index) => (
                <li key={index}>{amenity}</li>
              ))}
            </ul>
          </div>
          <div className={styles.imageContainer}>
            <img
              src={hotel.images[currentImageIndices[hotelIndex]]}
              alt={hotel.name}
              className={styles.hotelImage}
              onClick={() => handleClickImage(hotelIndex)}
            />
            <div className={styles.indicatorContainer}>
              {hotel.images.map((image, imageIndex) => (
                <span
                  key={imageIndex}
                  className={`${styles.indicator} ${
                    currentImageIndices[hotelIndex] === imageIndex
                      ? styles.active
                      : ''
                  }`}
                  onClick={() => handleIndicatorClick(hotelIndex, imageIndex)}
                />
              ))}
            </div>
          <a
            href={hotel.moreInfoUrl}
            target='blank'
            className={styles.moreInfoButton}
          >
            More info
          </a>
          </div>
          <a
            href={hotel.moreInfoUrl}
            target='blank'
            className={styles.moreInfoButton2}
          >
            More info
          </a>
        </div>
      ))}
    </div>
  );
};

export default Hotels;
