import React from 'react';
import styles from './Aboutus.module.css';
import About from './image.png';
import AboutSmall from '../Assets/Buildings.png'; 

const AboutUs = () => {
  return (
    <div className={styles.container}>
      <section className={styles.aboutTextSection}>
        <h2 className={styles.sectionHeader}>About us</h2>
        <p className={styles.aboutTextSmall}>
          We are a team of experts and enthusiasts passionate about the world of gambling and luxury vacations. Our years of experience and in-depth knowledge of the industry allows us to objectively assess and recommend only the best casino hotels in Australia. We travel throughout the country visiting various hotels and casinos to personally assess their level of service, comfort and entertainment provided.
        </p>
      </section>
      <section className={styles.aboutSection}>
        <img src={About} alt="About" className={styles.aboutImage} />
        <img src={AboutSmall} alt="About Small" className={styles.aboutImageSmall} />
      </section>
      <section className={styles.offerSection}>
        <h2 className={styles.sectionHeader}>What We Offer:</h2>
        <ul>
          <li>
            <strong>Detailed Reviews:</strong> We offer comprehensive reviews of casino hotels describing their amenities,
            entertainment, restaurants and unique features.
          </li>
          <li>
            <strong>Up-to-date news:</strong> Stay up-to-date on the latest events, special offers and new discoveries
            in the world of casino hotels.
          </li>
          <li>
            <strong>Helpful Tips:</strong> Our tips and advice will help you make the right choice and maximize your
            enjoyment of your stay.
          </li>
          <li>
            <strong>Reviews and Ratings:</strong> Unbiased reviews and ratings based on real reviews and our experience.
          </li>
        </ul>
      </section>
      <section className={styles.goalsPrinciplesSection}>
        <div className={styles.goals}>
          <h2 className={styles.sectionHeader}>Our Goals:</h2>
          <p>
            Our main goal is to provide you with unbiased and reliable reviews of the best hotels and casinos in Australia.
            We thoroughly research and analyze each establishment so that you can be sure of the quality of services provided
            and the level of service. Our reviews will help you choose the perfect place for accommodation and entertainment,
            taking into account all your preferences and requirements.
          </p>
        </div>
        <div className={styles.principles}>
          <h2 className={styles.sectionHeader}>Our Principles:</h2>
          <p>
            Objectivity and Honesty: We provide objective and honest reviews based on real experiences and guest feedback.
            <br />
            Quality and attention to detail: We pay attention to every detail so that you get the most complete picture of each hotel.
            Constant updates: We update our information regularly to keep you up to date with the latest news and developments.
          </p>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;